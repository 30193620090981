body {
  overflow: hidden;
  margin: 0; /* Reset default body margin */
}

.typing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: white;
}

.text-and-cursor {
  display: flex;
  align-items: center;
  text-align: center;
}

#typing-text {
  font-size: 4vw;
  color: black;
  font-weight: bold;
  font-family: 'Oswald', sans-serif;
}

.static-text {
  font-size: 2vw;
  color: black;
  font-weight: bold;
  font-family: 'Oswald', sans-serif;
}

.typed-cursor{
  width: 1vw;
  background-color: black;
  animation: blink 0.7s infinite;
  margin-left: 0.1vw; /* Add margin to separate cursor from text */
}

@media (min-width: 300px) {
  .typed-cursor {
    height: 5vw;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
